.homeMain .hero-section {
    max-width: 100vw;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.homeMain .hero-section img{
    width: 100vw;
    max-height: 100%;
}
.react-swipeable-view-container{
    width: 100vw;
}
.homeMain .hero-section .himg2{
    width: 20vw;
    height: 100%;
    border: 2px solid green;
    position: absolute;
    top: 0;
    left: 0;
    transition: left 5s ease;
    animation: imgFade 20s linear infinite;


    
}
@keyframes imgFade {
    0%, 100% { transform: translateX(0); }
  50% { transform: translateX(390%); } /* Move frame across the images */

  }
  
.homeMain .categories{
    margin-top: 30px;
    display: grid;
    /* grid-gap: 15px; */
    grid-template-columns: repeat(4,1fr);
}
.homeMain .categories .cate{
    /* width:400px; */
    /* height: 380px; */
    /* margin: 0 10px; */
    cursor: pointer;
}
.homeMain .categories .cate img{
    width: 100%;
    height: 100%;
}
.trending-collections{
    margin-top: 50px;
}

.shoes-section {
    margin: 30px 0;
    max-width: 100vw;

}
.shoes-section img{
    width: 100%;
    height: 100%;
  

}
@keyframes fadeInOut1 {
    0%, 50% {
      display: block;
    }
    50.1%, 100% {
      display: none;
    }
  }
  
  @keyframes fadeInOut2 {
    0%, 50% {
      display: none;
    }
    50.1%, 100% {
      display: block;
    }
  }
  
  .simg1, .simg2 {
    /* position: absolute; */
    height: 100%;
    transition: display 0.5s ease;
  }
  
  .simg1 {
    animation: fadeInOut1 2s infinite;
  }
  
  .simg2 {
    animation: fadeInOut2 2s infinite;
  }
.icon-footer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    text-align: center;
}
.icon-footer div{
    text-align: center;
    background-color: white;
    border-radius: 10px;
    padding: 10px 0;
    text-transform: uppercase;
}
.icon-footer img{
    width: 100px;
    height: 100px;
}
@media screen and (max-width:1200px) {
    /* .homeMain .hero-section img {
        width: 170%;
        min-height:70%;
        max-height: 80%;
    } */
}
@media screen and (max-width:950px){

    .homeMain .categories{

        /* grid-template-columns: 1fr 1fr;
        grid-gap: 1px; */
    }

}
@media screen and (max-width:650px) {
    .homeMain .hero-section {
        min-height: 20vh;
    }

    .trending-collections .trending-collection{
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }
    .homeMain .category-devide {
        width: 100vw;
        flex-direction: column;
    }

    .homeMain .category-devide div:hover {
        width: 90%;
        transition: all 0.3s;
    }

    .homeMain .category-devide div {
        width: 100%;
    }

    .shoes-section {

        grid-template-columns: 1fr;
        height: 100%;

    }

    .shoes-section .shoe-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 50px 20px;
        text-align: center;
    }
    .shoes-section .shoe-right p:nth-child(1) {
        font-size: 25px;

    }
    
    .shoes-section .shoe-right p:nth-child(2) {
    
        font-size: 16px;
    }
    .icon-footer{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        text-align: center;
    }
}