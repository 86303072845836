@import url('https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&display=swap');
:root{
    --paragraph-font:"Baskervville", serif;
    --heading-font:"Baskervville", serif;
    --bg-color:rgb(216, 216, 216);
    --price-color:#bc6611;
    
}
*{
    font-family: "Baskervville", serif;

}

